import { Controller } from "stimulus"
import * as utils from "src/js/stimulus_helper"

export default class extends Controller {
  static targets = [
    "frequency",
    "upfrontAmount",
    "regularPaymentAmount",
    "nextDirectDebitDateWithIndex",
    "newRegularPaymentText",
    "upfrontAmountTextSection",
    "upfrontAmountText",
    "loadingSpinner",
    "cancelButton"
  ]

  connect() {
    const frequency= this.data.get('current-frequency')
    const nextDirectDebitDateWithIndex = this.data.get('current-start-date-with-index')
    this.changeDirectDebitDetails(frequency, nextDirectDebitDateWithIndex)
  }

  changeDirectDebitDetails(frequency, nextDirectDebitDateWithIndex) {
    this.populateDirectDebitDatesOptions(frequency, nextDirectDebitDateWithIndex)
    const selectedNextDirectDebitDateWithIndex = this.nextDirectDebitDateWithIndexTarget.value
    this.updateDirectDebitDetails(frequency, selectedNextDirectDebitDateWithIndex)
    this.newRegularPaymentTextTarget.innerHTML = this.paymentOptions()[frequency]['regular_payment_amount_text']
    this.regularPaymentAmountTarget.value = this.paymentOptions()[frequency]['regular_payment_amount']
    this.upfrontAmountTarget.value = this.paymentOptions()[frequency]['dates'][selectedNextDirectDebitDateWithIndex]['upfront_amount'] || 0.0
  }

  populateDirectDebitDatesOptions(frequency, nextDirectDebitDateWithIndex) {
    const data = this.paymentOptions()[frequency]['dates']
    const selectBox = this.nextDirectDebitDateWithIndexTarget;
    selectBox.innerHTML = '';
    for ( const [date_with_index, paymentOptions] of Object.entries(data)) {
      const opt = document.createElement('option')
      opt.value = date_with_index
      opt.innerHTML = paymentOptions['start_date_text']
      if (date_with_index == nextDirectDebitDateWithIndex) {
        opt.selected = true
      }
      selectBox.appendChild(opt);
    }
  }

  updateDirectDebitDetails(frequency, nextDirectDebitDateWithIndex) {
    if (this.paymentOptions()[frequency]['dates'][nextDirectDebitDateWithIndex]) {
      const upfrontAmountText = this.paymentOptions()[frequency]['dates'][nextDirectDebitDateWithIndex]['upfront_amount_text']
      if (upfrontAmountText == null) {
        utils.hide(this.upfrontAmountTextSectionTarget)
      } else {
        this.upfrontAmountTextTarget.innerHTML = upfrontAmountText
        utils.show(this.upfrontAmountTextSectionTarget)
      }
    } else {
      utils.hide(this.upfrontAmountTextSectionTarget)
    }
  }

  paymentOptions() {
    return JSON.parse(this.data.get('paymentOptions'))
  }

  nextDirectDebitDateWithIndexChanged(e) {
    const nextDirectDebitDateWithIndex = e.target.value
    const frequency = this.frequencyTarget.value
    this.changeDirectDebitDetails(frequency, nextDirectDebitDateWithIndex)
  }

  frequencyChanged(e) {
    const frequency = e.target.value
    const nextDirectDebitDateWithIndex = this.nextDirectDebitDateWithIndexTarget.value
    this.changeDirectDebitDetails(frequency, nextDirectDebitDateWithIndex)
  }

}
